$('.confirm-remove-partner').on('click', function (e) {
    e.preventDefault();

    let link = $(this).attr('href');
    let confirmationModal = $('#remove-partner-confirmation-modal');

    confirmationModal.find('.confirm-destroy-route').attr('href', link);
    confirmationModal.modal('show');
});
$('.confirm-destroy-guarantee-card').on('click', function (e) {
    e.preventDefault();

    let link = $(this).attr('href');
    let confirmationModal = $('#destroy-guarantee-card-confirmation-modal');

    confirmationModal.find('.confirm-destroy-route').attr('href', link);
    confirmationModal.modal('show');
});

$('.add-partner-modal-opener').on('click', function (e) {
    e.preventDefault();

    let link = $(this).attr('href');
    let confirmationModal = $('#add-partner-modal');

    confirmationModal.find('form').attr('action', link);
    confirmationModal.modal('show');
});
