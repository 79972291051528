$('body').on('submit', 'form', function (e) {
    if ($(this).hasClass('ajax-form')) {
        e.preventDefault();

        let self = this;

        let submitButton = $(self).find('button[type=submit]').first();
        let oldSubmitMessage = submitButton.val() ? submitButton.val() : submitButton.text();
        if (submitButton) {
            submitButton.html(
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lädt...'
            );
        }

        $('.form-control').removeClass('is-invalid');
        $('.form-check-input').removeClass('is-invalid');

        if ($('.ajax-alert') !== undefined) {
            $('.ajax-alert').addClass('d-none');
        }

        let action = $(self).attr('action');
        let formData = new FormData(this);
        let method = $(self).attr('method');
        if (!method) {
            method = "post";
        }

        $.ajax({
            url: action,
            method: method,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            complete: function (result) {
                if (submitButton) {
                    submitButton.val(oldSubmitMessage).text(oldSubmitMessage);
                }
            },
            error: function (result) {
                let data = result.responseJSON;
                let errors = data.errors;
                console.log($(self))
                if (!jQuery.isEmptyObject(errors)) {
                    $('.invalid-feedback').html('');
                    $('.invalid-feedback').css('display', 'none');
                    let minOffsetTop = -100;
                    for (let field in errors) {
                        if (!errors.hasOwnProperty(field)) continue;
                        let message = errors[field][0];
                        var inputField;
                        let invalidFeedback = null;
                        if(field.startsWith('media')){
                            inputField = $(self).find('#media');
                        }else{
                            if($(self).hasClass('exists-multiple')){
                                inputField = $(self).find('[name="' + field.replaceAll(".", "_") + '"');
                            }else{
                                inputField = $(self).find('#'+field.replaceAll(".", "_"));
                            }
                            console.log(inputField);
                        }
                        if (inputField.is(':checkbox')) {
                            inputField.closest('.form-check').find('.form-check-input').addClass('is-invalid');
                            invalidFeedback = inputField.closest('.form-check').find('.invalid-feedback');
                            invalidFeedback.html(message);
                            invalidFeedback.css('display', 'block')
                        } else {
                            inputField.closest('.form-group').find('.form-control').addClass('is-invalid');
                            invalidFeedback = inputField.closest('.form-group').find('.invalid-feedback');
                            invalidFeedback.html(message);
                            invalidFeedback.css('display', 'block')
                        }

                        if(minOffsetTop < 0 || minOffsetTop > inputField.offset().top){
                            minOffsetTop = inputField.offset().top;
                        }

                    }
                    if(minOffsetTop >= 0){
                        window.scroll(0, minOffsetTop - 200);
                    }
                }
            },
            success: function (result) {
                if (result.hasOwnProperty('redirect')) {
                    document.location.href = result.redirect;
                }
            }
        });
    }
});
