$('.confirm-destroy').on('click', function (e) {
    e.preventDefault();

    let link = $(this).attr('href');
    let confirmationModal = $('#destroy-confirmation-modal');

    confirmationModal.find('.confirm-destroy-route').attr('href', link);
    confirmationModal.modal('show');
});
$('.confirm-ban').on('click', function (e) {
    e.preventDefault();

    let link = $(this).attr('href');
    let confirmationModal = $('#ban-confirmation-modal');

    confirmationModal.find('.confirm-ban-route').attr('href', link);
    confirmationModal.modal('show');
});
